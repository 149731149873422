<template>
  <div v-if="companyItemTypes">
    <table class="table is-bordered is-striped is-narrow is-fullwidth"
      :class="{ 'is-loading' : isFetchingSupplementaryQuote }">
      <thead>
        <tr>
          <th>Category</th>
          <template v-if="value.subQuoteNos.length > 1 && value.subQuoteNo === 0">
            <th v-for="(sub) in value.subQuoteNos"
              :key="sub.value"
              class="has-text-right is-subtotal">
              {{ sub.key === 0 ? 'Main' : `Add-${sub.key}` }}
            </th>
            <th class="has-text-right is-subtotal">
              Summary
            </th>
          </template>
          <template v-else-if="value.subQuoteNos.length === 1 || value.subQuoteNo > 0">
            <th class="has-text-right is-subtotal">
              Total
            </th>
          </template>
        </tr>
      </thead>
      <tfoot v-if="value.subQuoteNos.length === 1 || (showSupplementaryQuoteTotals) || value.subQuoteNo > 0"
        :class="[showSupplementaryQuoteTotals ? 'has-multiple-column-totals' : 'has-multiple-totals']">
        <tr>
          <th class="has-text-right">Total excl. GST</th>
          <th class="has-text-right has-text-success">{{ value.totalExGst | formatCurrency($userInfo.locale) }}</th>
          <template v-if="showSupplementaryQuoteTotals">
            <th v-for="(supp) in supplementaryQuotes"
              :key="supp.subQuoteNo"
              class="has-text-right has-text-success">
              {{ supp.totalExGst | formatCurrency($userInfo.locale) }}
            </th>
            <th class="has-text-right has-text-success">{{ calculateGrandTotal(value.totalExGst, supplementaryQuotes.map(q => q.totalExGst)) | formatCurrency($userInfo.locale) }}</th>
          </template>
        </tr>
        <tr>
          <th class="has-text-right">GST</th>
          <th class="has-text-right has-text-success">{{ value.totalExGst * (value.gstRate/100) | formatCurrency($userInfo.locale) }}</th>
          <template v-if="showSupplementaryQuoteTotals">
            <th v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right has-text-success">
              {{ supp.totalExGst * (supp.gstRate/100) | formatCurrency($userInfo.locale) }}
            </th>
            <th class="has-text-right has-text-success">{{ calculateGrandTotal(value.totalExGst * (value.gstRate/100), supplementaryQuotes.map(q => q.totalExGst * (q.gstRate/100))) | formatCurrency($userInfo.locale) }}</th>
          </template>
        </tr>
        <tr>
          <th class="has-text-right">Total incl. GST</th>
          <th class="has-text-right has-text-success">{{ value.totalIncGst | formatCurrency($userInfo.locale) }}</th>
          <template v-if="showSupplementaryQuoteTotals">
            <th v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right has-text-success">
              {{ supp.totalIncGst | formatCurrency($userInfo.locale) }}
            </th>
            <th class="has-text-right has-text-success">{{ calculateSummaryTotal() | formatCurrency($userInfo.locale) }}</th>
          </template>
        </tr>
      </tfoot>
      <tbody v-if="value.subQuoteNos.length === 1 || (showSupplementaryQuoteTotals) || value.subQuoteNo > 0">
        <tr v-show="isEnabled(itemCategoryTypes.RR)">
          <td>{{ getDisplayName(itemCategoryTypes.RR) }}</td>
          <td class="has-text-right">{{ value.rrTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.rrTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.rrTotal, supplementaryQuotes.map(q => q.rrTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.RWA)">
          <td>{{ getDisplayName(itemCategoryTypes.RWA) }}</td>
          <td class="has-text-right">{{ value.rwaTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.rwaTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.rwaTotal, supplementaryQuotes.map(q => q.rwaTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.REP)">
          <td>{{ getDisplayName(itemCategoryTypes.REP) }}</td>
          <td class="has-text-right">{{ value.repairTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.repairTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.repairTotal, supplementaryQuotes.map(q => q.repairTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.PAINT)">
          <td>{{ getDisplayName(itemCategoryTypes.PAINT) }}</td>
          <td class="has-text-right">{{ value.paintTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.paintTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.paintTotal, supplementaryQuotes.map(q => q.paintTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.FIBER)">
          <td>{{ getDisplayName(itemCategoryTypes.FIBER) }}</td>
          <td class="has-text-right">{{ value.fgTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.fgTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.fgTotal, supplementaryQuotes.map(q => q.fgTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.CRUSH)">
          <td>{{ getDisplayName(itemCategoryTypes.CRUSH) }}</td>
          <td class="has-text-right">{{ value.crushTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.crushTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.crushTotal, supplementaryQuotes.map(q => q.crushTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.CD)">
          <td>{{ getDisplayName(itemCategoryTypes.CD) }}</td>
          <td class="has-text-right">{{ value.cdTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.cdTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.cdTotal, supplementaryQuotes.map(q => q.cdTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.MECH)">
          <td>{{ getDisplayName(itemCategoryTypes.MECH) }}</td>
          <td class="has-text-right">{{ value.mechTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.mechTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.mechTotal, supplementaryQuotes.map(q => q.mechTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.PART)">
          <td>{{ getDisplayName(itemCategoryTypes.PART) }}</td>
          <td class="has-text-right">{{ value.partTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.partTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.partTotal, supplementaryQuotes.map(q => q.partTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.OPG)">
          <td>{{ getDisplayName(itemCategoryTypes.OPG) }}</td>
          <td class="has-text-right">{{ value.opgTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.opgTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.opgTotal, supplementaryQuotes.map(q => q.opgTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.MISC)">
          <td>{{ getDisplayName(itemCategoryTypes.MISC) }}</td>
          <td class="has-text-right">{{ value.miscTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.miscTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.miscTotal, supplementaryQuotes.map(q => q.miscTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
        <tr v-show="isEnabled(itemCategoryTypes.SUBL)">
          <td>{{ getDisplayName(itemCategoryTypes.SUBL) }}</td>
          <td class="has-text-right">{{ value.sublTotal | formatNumber($userInfo.locale) }}</td>
          <template v-if="showSupplementaryQuoteTotals">
            <td v-for="(supp) in supplementaryQuotes"
              :key="supp.quoteId"
              class="has-text-right">
              {{ supp.sublTotal | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-text-right">{{ calculateGrandTotal(value.sublTotal, supplementaryQuotes.map(q => q.sublTotal)) | formatNumber($userInfo.locale) }}</td>
          </template>
        </tr>
      </tbody>
    </table>
    <label v-if="hasPinnedRemarks"
      class="label">Pinned Remarks</label>
    <label v-if="!hasPinnedRemarks"
      class="label">Recent Remarks</label>
    <table v-if="value.subQuoteNos.length === 1 || (showSupplementaryQuoteTotals) || value.subQuoteNo > 0"
      class="table is-bordered is-striped is-narrow is-fullwidth">
      <thead />
      <tbody>
        <tr v-for="(remark) in displayRemarks"
          :key="remark.remarkId">
          <td class="is-content-width">
            <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
          </td>
          <td class="is-remark">
            <div>{{ remark.remarks }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { RemarkTypes, ItemCategoryTypes } from '@/enums'
import StoreMixin from './storeMixin'
import QuoteService from './QuoteService'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'QuoteDetailSummary',
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  props: {
    value: null
  },
  data() {
    return {
      supplementaryQuotes: [],
      isFetchingSupplementaryQuote: false
    }
  },
  computed: {
    hasPinnedRemarks() {
      return this.pinnedActiveRemarks && this.pinnedActiveRemarks.length > 0
    },
    pinnedActiveRemarks() {
      const pinnedActiveRemarks = this.value.remarks.filter(
        r => r.pinRemarkOrder !== null && r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted)
      return pinnedActiveRemarks.sort((a, b) => {
        return a.pinRemarkOrder - b.pinRemarkOrder
      })
    },
    displayRemarks() {
      const unpinnedActiveRemarks = this.value.remarks.filter(
        r => r.pinRemarkOrder == null && r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted)
      unpinnedActiveRemarks.sort((a, b) => {
        return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
      })
      return this.hasPinnedRemarks ? this.pinnedActiveRemarks.slice(0, 5) : unpinnedActiveRemarks.slice(0, 5)
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    showSupplementaryQuoteTotals() {
      return this.value.subQuoteNos.length > 1 && this.value.subQuoteNo === 0 && !this.isFetchingSupplementaryQuote && this.supplementaryQuotes.length > 0
    },
    test() {
      return this.supplementaryQuotes.map((q) => q.rwaTotal)
    }
  },
  created() {
    this.getSupplementaryQuoteTotals()
  },
  mounted() {},
  methods: {
    getDisplayName(itemCategoryType) {
      if (this.companyItemTypes) {
        const itemType = this.companyItemTypes.find((i) => i.itemCategoryType === itemCategoryType)
        if (itemType) {
          return itemType.displayName
        }
      }
      return itemCategoryType
    },
    isEnabled(itemCategoryType) {
      if (this.companyItemTypes) {
        const itemType = this.companyItemTypes.find((i) => i.itemCategoryType === itemCategoryType)
        if (itemType) {
          return itemType.enabled
        }
      }
      return false
    },
    async getSupplementaryQuoteTotals() {
      if (this.value.subQuoteNo === 0) {
        this.isFetchingSupplementaryQuote = true
        this.supplementaryQuotes = await QuoteService.getSupplementaryQuoteTotals(this.value.quoteId)
      }
      this.isFetchingSupplementaryQuote = false
    },
    calculateGrandTotal(mainCategorytotal, supplementaryCategoryTotalsArray) {
      const suppCategoryTotal = supplementaryCategoryTotalsArray.reduce(function (total, suppTotal) {
        return total + suppTotal
      }, 0)
      return mainCategorytotal + suppCategoryTotal
    },
    calculateSummaryTotal() {
      const suppTotalExGst = this.supplementaryQuotes
        .map((q) => q.totalExGst)
        .reduce(function (total, suppTotal) {
          return roundAwayFromZero(total + suppTotal)
        }, 0)
      const gstTotal = roundAwayFromZero((this.value.totalExGst + suppTotalExGst) * (this.value.gstRate / 100))
      const totalExGst = roundAwayFromZero(this.value.totalExGst + suppTotalExGst)
      return roundAwayFromZero(totalExGst + gstTotal)
    }
  }
}
</script>

<style lang="scss" scoped>
th.is-subtotal {
  width: 7em;
}
</style>
